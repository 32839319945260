<template>
  <div class="about">
    <div class="container">
      <div class="section-title">
        <h2>About Us</h2>
      </div>

      <div class="row content">
        <div class="col-lg-6">
          <p>
            Meraki Facades provides high quality of service & products to our
            clients where we ensure to fulfill the desires of client & architect
            in project with soul and creativity.
          </p>
          <p>
            Our predefined product services are listed below, but we are proud
            to say that we can provied products and services as per your
            requirement to fulfill your dream.
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i> Elevations & Office Partition
            </li>
            <li><i class="ri-check-double-line"></i> ACP & LAMINATE</li>
            <li><i class="ri-check-double-line"></i> CANOPIES</li>
            <li><i class="ri-check-double-line"></i> Railings</li>
            <li><i class="ri-check-double-line"></i> Louvers & Framework</li>
          </ul>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p>
            We deal with major brands like Saint Gobain, Jindal, Ozone, Doorma
            and many more to absolute finishing to work with respectfull
            workmenship.
          </p>
          <router-link to="/get-quote" class="btn-theme-white"
            >Get Quote</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
